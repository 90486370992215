import { createApp, h } from 'vue'

const NotFoundComponent = { template: '<p>Page not found</p>' }
import SalesComponent from './components/Sales.vue'
import LoginComponent from './components/Login.vue'

import './assets/styles.css';

const routes = {
    '/': LoginComponent,
    '/sales': SalesComponent
}

const app = {
    data: () => ({
        currentRoute: window.location.pathname
    }),

    computed: {
        CurrentComponent() {
            return routes[this.currentRoute] || NotFoundComponent
        }
    },

    render() {
        return h(this.CurrentComponent)
    }
}

createApp(app)
    .mount('#app')
