<template>
<div class="ui container" v-if="!data">
  
    <div class="ui active inverted dimmer">
      <div class="ui text loader">Loading sales data, please wait...</div>
    </div>
  
</div>

<div class="ui container" v-if="data">
  <br />
    <h1 class="ui header">Latest Sales</h1>
    <div style='margin-bottom: 10px; color: #999;'>{{curDateTime}}</div>
    <p>The sales data shown is to the end of the previous month or the month prior to that if sales reports are yet to be received and processed. Print sales reports are generally received in the first week of the month and ebook sales by the middle of the month.</p>

      <div class="ui fluid card" v-for="book in data" v-bind:key="book.bookID" style='background: #333; color: white;'>
        <div class="content">
          <h3 style='margin: 0; padding: 0;'>{{book.title}}<span v-if="book.edition">, Edition: {{book.edition}}</span></h3>
          <div class="meta" style='color: #ccc;'>
            <span class="date">Book ID: {{book.bookID}}</span>
          </div>
          <div class="description">              
              <table class="ui celled striped table">
                <thead>
                  <tr>
                    <th style='width: 15%;'>Format</th>
                    <th style='width: 20%;'>Statement Months<div style='font-size: 0.9em; font-weight: normal;' class="lowlight">(Statements sent on the 1st of the subsequent months)</div></th>
                    <th>Statement Receiver(s)</th>
                    <th>Sales Breakdown</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="detail in book.book_detail" v-bind:key="detail.bookCode">
                    <td>
                      <strong>{{detail.format}}</strong>
                      <div style='font-size: 0.9em;'>Published: {{formatDate(detail.published)}}</div>
                      <div style='font-size: 0.9em;' class="meta">Format ID: {{detail.bookCode}}</div>
                    </td>
                    <td>
                      <div v-for="freq in detail.statement_freq" v-bind:key="freq">{{monthNames[parseInt(freq,10)]}}</div>
                    </td>
                    <td>
                      <div v-for="rec in detail.statement_receivers" v-bind:key="rec">{{rec}}</div>
                    </td>
                    <td>
                      <table class="ui very basic collapsing celled table" style='width: 100%;' v-if="detail.format.toLowerCase()!=='ebook'">
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th>Trade</th>
                            <th>Direct</th>
                            <th>Corporate</th>
                          </tr>
                          <tr>
                            <th>UK</th>
                            <td>{{detail['sales'][0]}}</td>
                            <td>{{detail['sales'][1]}}</td>
                            <td>{{detail['sales'][2]}}</td>
                          </tr>
                          <tr>
                            <th>US</th>
                            <td>{{detail['sales'][3]}}</td>
                            <td>{{detail['sales'][4]}}</td>
                            <td>{{detail['sales'][5]}}</td>
                          </tr>  
                          <tr>
                            <th>ROW</th>
                            <td>{{detail['sales'][6]}}</td>
                            <td>{{detail['sales'][7]}}</td>
                            <td>{{detail['sales'][8]}}</td>
                          </tr>                                                    
                        </thead>
                      </table>
                      <h5>Total: {{detail['sales'][9]}}</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
          <br />
              If you have an queries about sales, suggestions for further information you would like to see on this report or would like to update the email address that your royalty statements are sent to, please contact: <a href="mailto:harriman@harriman-house.com">harriman@harriman-house.com</a>
        </div>
      </div>

    <!--<div v-for="book in data.books" v-bind:key="book.bookID">
      <h3>{{book.book_master.title}}</h3>
      <div v-for="detail in book.book_master.book_detail" v-bind:key="detail.bookCode">
        <h4>{{detail.format}}</h4>
        <div v-for="contract in detail.royalty_contracts" v-bind:key="contract.RcnIndex">
          <strong>Contract {{contract.RcnIndex}}</strong><br />
            <a v-for="freq in contract.statement_frequency" v-bind:key="freq.month" class="ui blue label">{{monthNames[freq.month]}}</a>
        </div>
      </div>
      <hr />
    </div>
    -->
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'Sales',
    data() {
      return {
        data: false,
        curDateTime: moment().format("dddd, MMMM Do YYYY, h:mma"),
        jwt: '',
        monthNames: ["","January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November","December"]
      };
    },
    methods: {
        formatDate: function(in_date) {
            if (!in_date) {
                return '-'
            }
            return moment(in_date,'YYYY-MM-DD').format('DD/MM/YYYY')
        },     
    },
    created() {
        this.jwt = localStorage.getItem('jwt')
        if (!this.jwt) {
            this.$root.currentRoute = '/'
        }

        fetch('https://apiv3.hhouse.co/authorPortal/sales', {
          //fetch('http://apiv3.hhouse.fantata/authorPortal/sales', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.jwt
            }
        })
        .then(response => response.json())
        .then(data => {
            this.data = data
            console.log(data)
        })
        .catch((error) => {
            console.error('Error:', error);
            // logout
            localStorage.removeItem('jwt')
            localStorage.removeItem('liUser')            
            this.$root.currentRoute = '/'
        });

    },
  }
</script>

<style>
  h3 {
    margin-bottom: 5%;
  }
</style>