<template>

  <Header />

    <div class="ui middle aligned center aligned grid">
        <div class="column">
            <br />
            <h2 class="ui black image header">
            
            <div class="content">
                Harriman House Author Reporting
            </div>
            </h2>
            <form class="ui large form">
            <div class="ui segment">
                <div class="field">
                <div class="ui left icon input">
                    <i class="user icon"></i>
                    <input v-model="email" type="text" name="email" placeholder="E-mail address">
                </div>
                </div>
                <div class="field">
                <div class="ui left icon input">
                    <i class="lock icon"></i>
                    <input v-model="password" type="password" name="password" placeholder="Password">
                </div>
                </div>
                <div @click="login()" class="ui fluid large blue submit button">Login</div>
            </div>

            <div class="ui error message"></div>

            </form>
        </div>
    </div>

  <Footer />

</template>

<script>

    import Header from './Header.vue'
    import Footer from './Footer.vue'

    export default {
        name: 'Login',
        components: {
            Header,
            Footer
        },
        data() {
            return {
                email: null,
                password: null
            }
        },
        created() {
            let jwt = localStorage.getItem('jwt')
            if (jwt) {
                this.$root.currentRoute = '/sales'
            }
        },
        methods: {
            login() {

                const data = { username: this.email, password: this.password};

                fetch('https://apiv3.hhouse.co/login', {
                //fetch('http://apiv3.hhouse.fantata/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                .then(response => response.json())
                .then(data => {
                    if (data == 0) {
                        // not logged in
                        localStorage.removeItem('jwt')
                        localStorage.removeItem('liUser')
                    } else {
                        localStorage.setItem('jwt', data.access_token)
                        localStorage.setItem('liUser', this.email)
                        this.$root.currentRoute = '/sales'
                        console.log(data.access_token)
                    }
                    
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            }
        }
    }

</script>

<style type="text/css">
    body > .grid {
      height: 100%;
    }
    .column {
      max-width: 450px;
    }
  </style>